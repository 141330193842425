<template>
  <div>
    <CCard>
      <CCardHeader>
        <div>
          <TextScroll :isVis="isVis" :dataList="textArr" @handlerNotice="getAnnouncement"></TextScroll>
        </div>
        <slot name="header">
          <div class="hed-box">
            <div class="box">
              <div class="wrp">
                <div class="i-group">
                  <div class="i-input">
                    <input
                    type="text"
                    v-model="forms.card_number"
                    :placeholder="$t('CardDetail.Card')"
                    class="input"
                  />
                  </div>
                </div>
                <div class="i-group">
                  <div class="i-input">
                    <el-date-picker
                    style="width:190px"
                    v-model="forms.afterDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('CardDetail.AfterDate')"
                  >
                  </el-date-picker>
                    
                  </div>
                </div>
                <div class="i-group lg sm-qu-margin">
                <!-- <div>时间后</div> -->
                <div class="i-input">
                  <el-date-picker
                    style="width:190px"
                    v-model="forms.beforeDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('CardDetail.BeforeDate')"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="i-group lg sm-qu-margin">
                <!-- <div>{{ $t('CardDetail.Table.Status') }}</div> -->
                <div class="i-input">
                  <el-select
                    style="width:178px"
                    v-model="forms.atype"
                    :placeholder="$t('CardDetail.SelectType')"
                  >
                    <el-option :label="$t('CardDetail.Deposit')" value="Deposit"></el-option>
                    <el-option :label="$t('CardDetail.Withdraw')" value="Withdraw"></el-option>
                    <el-option :label="$t('CardDetail.Topup')" value="Topup"></el-option>
                    <el-option :label="$t('CardDetail.TopupFee')" value="TopupFee"></el-option>
                    <el-option :label="$t('CardDetail.IssueFee')" value="IssueFee"></el-option>
                    <el-option :label="$t('CardDetail.TransactionFee')" value="TransactionFee"></el-option>
                    <el-option :label="$t('CardDetail.DeclineFee')" value="DeclineFee"></el-option>
                    <el-option :label="$t('CardDetail.Guarantee')" value="Guarantee"></el-option>
                  </el-select>
                  <!-- <select name="select" id="select" class="sel" v-model="forms.status">
                     <option value="Pending">Pending</option>
                     <option value="Complete">Complete</option>
                     <option value="Decline">Decline</option>
                    </select>    -->
                </div>
              </div>
              <div style="margin-left: 10px" class="i-group">
                <div>
                  <button class="s-btn" @click="search">
                    <!-- <CIcon name="cilMagnifyingGlass" /> -->
                    <!-- {{ $t("CardDetail.Search") }} -->
                    <CIcon name="cilMagnifyingGlass" />
                  </button>
                </div>
                <div @click="clearData">
                  <button class="s-btn" style="margin-left: 10px">
                    <!-- {{ $t("CardDetail.Clear") }} -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height:16px">
                    <polygon fill="var(--ci-primary-color, currentColor)" points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" class="ci-primary"/>
                  </svg>
                  </button>
                </div>
              </div>
              <div style="text-align:right;margin-top:12px">
                <a style="color: #1675D4;margin-left:20px" :href="`${ori}/user-guide/${getLa}/fund-detail/VirtualCard.html`" target="_blank">{{$t('CardDetail.Guide')}}</a>
              </div>
              </div>
              
            </div>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :hover="hover"
          :striped="striped"
          :small="small"
          :fixed="fixed"
          :items="items"
          :fields="tableFields"
          :items-per-page="20"
          :dark="dark"
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
              <el-tooltip placement="top">
                  <div slot="content">
                    <div>{{$t('AccountDetail.FrozenWithdrawTip')}}</div>
                  </div>
                    <i v-show="item.status == 'Frozen' && item.type == 'Withdraw'" style="cursor:pointer;color:#E6A23C;font-size:16px;vertical-align: -1px" class="el-icon-question"></i>
                </el-tooltip>
              <div style="color:#fff;padding-top:5px"><CButton size="sm" v-if="item.status == 'Decline'" block color="secondary" variant="outline">原因</CButton></div>
            </td>
          </template>

          <template #type="{ item }">
            <td>
              <div>{{ item.type }}
                <el-tooltip placement="top">
                  <div slot="content">
                    <div>{{$t('VirtualCard.StaticFeild.ReconciledTip')}}</div>
                  </div>
                    <i v-show="item.type == 'Reconcile'" style="cursor:pointer;color:#E6A23C;font-size:16px;vertical-align: -1px" class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </td>
          </template>
          
          <!-- <template #Type="{ item }">
              <td>
                  <CButton block color="secondary" variant="outline" size="sm">{{ item.Type }}</CButton>
              </td>
          </template> -->

          <template #amount="{ item }">
              <td>
                  <!-- <div :class="item.Amount.includes('-') ? 'f' : 'z'" style="font-weight: bold">{{ item.amount }}</div> -->
                  <div :class="item.amount > 0 ? 'z' : 'f'" style="font-weight: bold">${{ formatNum(item.amount) }}</div>
              </td>
          </template>

          <template #card_number="{ item }">
              <!-- <td>
                  <div>
                      <div>{{item.Card.name}}</div>
                      <div style="font-size: 14px;color: #CED2D8">{{fotmastStr(item.Card.code)}}</div>
                  </div>
              </td> -->
              <td>
                  <!-- <div>
                      <div>{{item.ConsumerCard.name}}</div>
                      <div style="font-size: 14px;color: #CED2D8">{{fotmastStr(item.ConsumerCard.code)}}</div>
                  </div> -->
                <div style="cursor: pointer;" @click="showM">
              <div class="icon-box">
                <!-- <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#728EFF" style="height:42px">
                    <rect width="256" height="42" x="128" y="192" class="ci-primary"/>
                    <rect width="128" height="42" x="128" y="304" class="ci-primary"/>
                    <path d="M48,432H464V88H48ZM80,120H432V400H80Z" class="ci-primary"/>
                  </svg>
                </div> -->
                <div class="tds">
                  <!-- <CIcon name="cib-visa" /> -->

                  <!-- <div>{{item.Card.name}}</div> -->
                  <div class="visa-box">
                    <!-- <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="#3C58A5"
                      >
                        <path
                          d="M23.26 11.495c-0.515-0.192-1.323-0.401-2.323-0.401-2.557 0-4.364 1.292-4.375 3.141-0.021 1.359 1.281 2.125 2.265 2.583 1.011 0.469 1.349 0.761 1.344 1.177-0.005 0.641-0.808 0.927-1.547 0.927-1.027 0-1.584-0.14-2.443-0.5l-0.319-0.151-0.364 2.136c0.625 0.255 1.745 0.479 2.901 0.5 2.724 0 4.5-1.281 4.52-3.256 0.021-1.083-0.676-1.905-2.161-2.583-0.896-0.437-1.457-0.735-1.457-1.183 0-0.396 0.479-0.812 1.479-0.812 0.859-0.016 1.464 0.172 1.937 0.359l0.245 0.109 0.359-2.057zM29.907 11.271h-2c-0.625 0-1.089 0.167-1.365 0.787l-3.844 8.713h2.719l0.548-1.427 3.317 0.005c0.083 0.333 0.317 1.421 0.317 1.421h2.401zM12.88 11.193h2.589l-1.62 9.504h-2.589l1.62-9.509zM6.297 16.427l0.265 1.323 2.536-6.479h2.745l-4.084 9.484h-2.733l-2.24-8.031c-0.047-0.14-0.104-0.235-0.245-0.317-0.755-0.396-1.604-0.719-2.541-0.943l0.031-0.199h4.177c0.563 0.021 1.021 0.199 1.177 0.803l0.912 4.364zM26.703 17.401l1.037-2.661c-0.016 0.025 0.213-0.547 0.343-0.907l0.177 0.817 0.6 2.745h-2.157z"
                        />
                      </svg>
                    </div> -->
                    <div>{{ item.card_number }}</div>
                  </div>
                </div>
              </div>
            </div>  
              </td>
          </template>

          <template #remark="{ item }">
              <td>
                  <div>
                      <div>{{item.remark}} <i style="margin-left: .5rem;cursor:pointer;font-weight:bold" v-if="item.remark == 'USDT Deposit'" class="el-icon-search" @click="toTron(item.ref)"></i></div>
                      <!-- <div style="font-size: 14px;color: #CED2D8">{{item.remark}}</div> -->
                  </div>
              </td>
          </template>
          <template #description="{ item }">
              <td>
                  <div>
                      <div>{{item.description}} 
                        <i style="margin-left: .5rem;cursor:pointer;font-weight:bold" v-if="item.type == 'TransactionFee'" class="el-icon-search" @click="seeTransactionFee(item.ref)"></i>
                        <i style="margin-left: .5rem;cursor:pointer;font-weight:bold" v-if="item.type == 'DeclineFee'" class="el-icon-search" @click="seeDeclineFee(item.ref)"></i>
                      </div>
                      <!-- <div style="font-size: 14px;color: #CED2D8">{{item.remark}}</div> -->
                  </div>
              </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pages"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="20"
          @current-change="handerPage"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </CCardFooter>
    </CCard>
    <!-- transactionfee詳情弹窗 -->
    <div>
      <CModal
        title=""
        :show.sync="showStatic"
        size=""
        :closeOnBackdrop="false"
      >
        <div class="modal-bodys">
          <div class="box-content">
            <CRow>
              <CCol md="6">
                <div class="m-item" style="padding-left:20px">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.CardNumber") }}
                  </div>
                  <div class="m-item-value">{{ transactionFeeData.card_number }}</div>
                </div>
              </CCol>
              <CCol md="6">
                <div class="m-item">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.Merchant") }}
                  </div>
                  <div class="m-item-value">{{ transactionFeeData.merchant_name }}</div>
                </div>
              </CCol>
              <CCol md="6">
                <div class="m-item" style="padding-left:20px">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.Amount") }}
                  </div>
                  <div class="m-item-value">{{ transactionFeeData.auth_amt }}</div>
                </div>
              </CCol>
              <CCol md="6">
                <div class="m-item">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.Status") }}
                  </div>
                  <div class="m-item-value">
                    <!-- ${{ showTData.available_amount }} -->
                    {{ transactionFeeData.status }}
                  </div>
                </div>
              </CCol>
              <CCol md="12">
                <CListGroup flush>
                  <CListGroupItem
                    ><div class="list-item">
                      {{ $t("AccountDetail.Statistics.TransactionTime") }}：<span
                        style="font-weight: bold"
                        >{{ transactionFeeData.auth_time }}</span
                      >
                    </div></CListGroupItem
                  >
                  <!-- <CListGroupItem
                    ><div class="list-item">
                      {{ $t("AccountDetail.StaticFeild.TotalRefund") }}：<span
                        style="font-weight: bold"
                        >2324243</span
                      >
                    </div></CListGroupItem
                  > -->
                </CListGroup>
              </CCol>
            </CRow>
          </div>
        </div>
        <template #footer>
          <CButton
            color=""
            @click="cancelSee"
            style="background: #fff; border: 1px solid #e8e8e8"
          >
            {{ $t("AccountDetail.Statistics.Close") }}
          </CButton>
        </template>
      </CModal>
    </div>
    <!-- declinefee詳情彈窗 -->
    <div>
      <CModal
        title=""
        :show.sync="showDeclineFee"
        size=""
        :closeOnBackdrop="false"
      >
        <div class="modal-bodys">
          <div class="box-content">
            <CRow>
              <CCol md="6">
                <div class="m-item" style="padding-left:20px">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.CardNumber") }}
                  </div>
                  <div class="m-item-value">{{ declineFeeData.card_number || "" }}</div>
                </div>
              </CCol>
              <CCol md="6">
                <div class="m-item">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.Merchant") }}
                  </div>
                  <div class="m-item-value">{{ declineFeeData.merchant_name || "" }}</div>
                </div>
              </CCol>
              <CCol md="6">
                <div class="m-item" style="padding-left:20px">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.Amount") }}
                  </div>
                  <div class="m-item-value">{{ declineFeeData.auth_amt || "" }}</div>
                </div>
              </CCol>
              <CCol md="6">
                <div class="m-item">
                  <div class="m-item-text">
                    {{ $t("AccountDetail.Statistics.Status") }}
                  </div>
                  <div class="m-item-value">
                    <!-- ${{ showTData.available_amount }} -->
                    {{ declineFeeData.status || "" }}
                  </div>
                </div>
              </CCol>
              <CCol md="12">
                <CListGroup flush>
                  <CListGroupItem
                    ><div class="list-item">
                      {{ $t("AccountDetail.Statistics.TransactionTime") }}：<span
                        style="font-weight: bold"
                        >{{ declineFeeData.auth_time || "" }}</span
                      >
                    </div></CListGroupItem
                  >
                  <!-- <CListGroupItem
                    ><div class="list-item">
                      {{ $t("AccountDetail.StaticFeild.TotalRefund") }}：<span
                        style="font-weight: bold"
                        >2324243</span
                      >
                    </div></CListGroupItem
                  > -->
                </CListGroup>
              </CCol>
            </CRow>
          </div>
        </div>
        <template #footer>
          <CButton
            color=""
            @click="cancelDeclineSee"
            style="background: #fff; border: 1px solid #e8e8e8"
          >
            {{ $t("AccountDetail.Statistics.Close") }}
          </CButton>
        </template>
      </CModal>
    </div>
  </div>
</template>

<script>
import { CDropdown } from "@coreui/vue";
import {Shopping} from '@icon-park/vue'
import '@icon-park/vue/styles/index.css'
import { CModal, CModalBody, CModalHeader, CModalFooter } from "@coreui/vue";
import { getCardDetail, getPageNotice } from "../api/http"
import TextScroll from "../components/TextScroll.vue"
export default {
  name: "Table",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return [
          
          { key: "CreateTime", label: "時間", _style: "vertical-align: middle; " },
          { key: "Type", label: "類型", _style: "" },
          { key: "Amount", label: "金額", _style: ""  },
          { key: "Status", label: "狀態", _style: ""  },
          { key: "Card", label: "卡", _style: "" },
          { key: "Remark", label: "備注", _style: "" },
          { key: "Description", label: "説明", _style: "" }
        ];

        // return ['卡号', '持卡人', '有效期', '安全码', '余额', '已消费', '状态', '创建时间', '操作']
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    pages: Number
  },
  data() {
    return {
      visibleVerticallyCenteredDemo: false,
      flat: false,
      forms: {
        beforeDate: "",
        afterDate: "",
        card_number: "",
        atype: ""
      },
      ori: "",
      showStatic: false,
      showDeclineFee: false,
      transactionFeeData: "",
      declineFeeData: "",
      textArr: [
        // {
        //   id: 1,
        //   val: '卡账户'
        // }
      ],
      isVis: false
    };
  },
  methods: {
    // 请求通告数据
    getAnnouncement(noticeType) {
      let obj = {
        uid: window.localStorage.getItem("uid"),
        type: "get_page_notice",
        // page: "fund_fund"
        page: noticeType
      }
      getPageNotice(obj).then((res) => {
        console.log("notice==>", res)
        if(res.code == 200) {
          if(res.data.length !== 0) {
            this.isVis = true
            this.textArr = res.data
          }else {
            this.isVis = false
          }
        }
      })
    },
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    handerPage(e) {
      console.log('page e==>', e)
      this.$emit(
        "searchData",
        this.forms.beforeDate,
        this.forms.afterDate,
        this.forms.card_number,
        this.forms.atype,
        e
      );
    },
    toTron(ref) {
      console.log('ref---->', ref)
      window.open(`https://tronscan.org/#/transaction/${ref}`, '_blank')
    },
    // 查看虚拟卡交易手续费详情
    seeTransactionFee(ref) {
      this.showStatic = true
      this.reqTransaction(ref, "transactionfee")
    },
    // 查看declinefee詳情
    seeDeclineFee(ref) {
      this.showDeclineFee = true
      this.reqTransaction(ref, "declinefee")
    },
    reqTransaction(ref, ty) {
      let data = {
        type: "get_card_transaction",
        uid: localStorage.getItem("uid"),
        tid: ref,
        before: "",
        after: "",
        card_number: "",
        tag: "",
        page: 1,
        page_size: 20
      }
      getCardDetail(data).then((res) => {
        // console.log("static res===>", res)
        if(res.data.length == 0) {
          this.declineFeeData = {}
          this.transactionFeeData = {}
          return
        }
        if(res.code == 200) {
          if(ty == "transactionfee") {
            this.transactionFeeData = res.data[0]
          }else if(ty == "declinefee") {
            this.declineFeeData = res.data[0]
          }else {

          }
        }
      })
    },
    cancelSee() {
      this.showStatic = false
    },
    cancelDeclineSee() {
      this.showDeclineFee = false
    },
    search() {
      this.$emit(
        "searchData",
        this.forms.beforeDate,
        this.forms.afterDate,
        this.forms.card_number,
        this.forms.atype,
      );
    },
    clearData() {
      this.forms.beforeDate = ""
      this.forms.afterDate = ""
      this.forms.card_number = ""
      this.forms.atype = ""
    },
    handleSizeChange(e) {
      console.log('eee==>', e)
    },
    getBadge(Status) {
      return Status === "Complete"
        ? "success"
        : Status === "Pending"
        ? "info"
        : Status === "Frozen"
        ? "danger"
        : Status === "Declined"
        ? "danger"
        : Status === "Cancel"
        ? "dark"
        : "success";
    },
    showM() {
      console.log("122");
      this.visibleVerticallyCenteredDemo = true;
    },
    fotmastStr(str) {
        return str.replace(/^(\d{4})\d+(\d{4})$/, "****$2")
    }
  },
  computed: {
    getLa() {
      // return localStorage.getItem("lang")
      let la = this.$i18n.locale
      if(la == 'en') {
        return 'en-US'
      }else {
        return this.$i18n.locale
      }
    },
    tableFields() {
      const fields = [
          
          { key: "create_time", label: this.$t('AccountDetail.Time'), _style: "vertical-align: middle; " },
          { key: "type", label: this.$t('AccountDetail.Type'), _style: "" },
          { key: "amount", label:this.$t('AccountDetail.Amount'), _style: ""  },
          { key: "status", label: this.$t('AccountDetail.Status'), _style: ""  },
          { key: "card_number", label: this.$t('AccountDetail.Card'), _style: "" },
          { key: "remark", label: this.$t('AccountDetail.Remark'), _style: "" },
          { key: "description", label: this.$t('AccountDetail.Description'), _style: "" }
        ];
       return fields 
    }
  },
  created() {
    this.ori = window.location.origin
  },
  components: {
    CDropdown,
    CModal,
    CModalBody,
    CModalHeader,
    CModalFooter,
    Shopping,
    TextScroll
  },
};
</script>
<style scoped lang="scss">
.box-content {
  .m-item {
    padding: 0.3rem 0;
    .m-item-text {
      font-size: 13px;
    }
    .m-item-value {
      font-weight: bold;
    }
  }
}
.list-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .box{
    display: flex;
    align-items: center;
  } */
  .hed-box{
    .box{
      margin-bottom: 10px;
      .i-group{
        display: flex;
        align-items: center;
        .i-input{
          margin-left: 10px;
          input{
            border: 0;
            outline: 0;
            border-radius: 20px;
            padding: 4px 2px;
            text-indent: 10px;
            border: 1px solid #f1f1f2;
          }
        }
      }
    }
  }
.title {
  width: 150px;
}
.wrp {
  display: flex;
  align-items: center;
  // .btn-ghost-info{
  //   color: #000 !important;
  // }
  .form-group {
    margin: 0;
  }
}
.ddd {
  color: #fff;
}
.ds {
  color: #000;
}
.con-box{
  padding-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.csu-box{
    display: flex;
    align-items: center;
}
.f{
    color: #FF0000;
}
.z{
    // color: #98FA1C;
    color: #00BEA4;
}
.visa-box {
  display: flex;
  align-items: center;
}
.icon-box {
  display: flex;
  align-items: center;
  justify-content: start;
  // padding: 0 12px;
}
.tds{
  margin-left: 0px;
}

.s-btn {
    border: 0;
    outline: 0;
    padding: 4px 20px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid #f1f1f2;
}
.s-btn:hover {
    background: #00bea4;
    border-color: #00bea4;
    color: #fff;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
</style>